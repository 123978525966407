import React, { useEffect, useState } from "react";
import style from "./home.module.css";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
import { Row, Col } from "react-bootstrap";

import CenterList from "./CenterList";
import { Link } from "react-router-dom";
import { getAllCenters } from "../../apis/CenterApis";
import { useDispatch } from "react-redux";
import useGetUser from "../../customHook/useGetUser";

import Action from "./Action";
import CardPage from "./Cardpage";
import Mission from "./Mission";

import Movement from "./Movement";
import Newsletter from "./newsletter";
import Neighbour from "./neighbour";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet-async";
const ForgottenWomen = () => {
  useGetUser();

  const dispatch = useDispatch();
  const [centerList, setCenterList] = useState();
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  useEffect(() => {
    dispatch(getAllCenters({ page: 1, limit: 6 })).then((res) => {
      if (res?.centersList) {
        setCenterList(res?.centersList);
      }
    });
  }, []);

  return (
    <>
      <div className={style.abort_main}>
        {/* <div className={`text-black ${style.dashboard_row}`}> */}
        <Row style={{ marginRight: "0px" }}>
          <Col md={6} className={style.abortimg}>
            <img
              src="/forgotten.png"
              alt="forgotten"
              // playing={false}
              // loop={true}
              // controls={false}
              // muted={true}
              // width="100%"
              // height="100%"
              // playsinline={true}
              style={{
                width: "90%",
                // height: "100%",
                // objectFit: "cover", // Ensures the image covers the container without distortion
                // position: "relative",
                // zIndex: 1,
                // position: "relative",
                // zIndex: 1,
                // background: "black",
              }}
            />

            <VideoPlayer
              showVideoPlayer={showVideoPlayer}
              setShowVideoPlayer={setShowVideoPlayer}
              videoUrl={videoUrl}
            />
          </Col>
          <Col md={6} className={style.bandata}>
            <div className={style.bancol}>
              <h1 className={style.bannerHeading3}>
                Who Are the Forgotten Women?
              </h1>

              <p className={style.bannerHeading2}>
                They are the countless women who faced seemingly insurmountable
                obstacles and felt they had no ‘choice.’ These women, along with
                those grappling with the mental and emotional repercussions,
                have been omitted from the public discourse on the abortion
                debate as if they don’t exist. Their stories and struggles
                deserve to be acknowledged and supported.
                {/* Countless women have terminated their pregnancies due to what
                they perceived as insurmountable obstacles that left them
                essentially without a 'choice' and many others are left
                struggling with the psychological consequences. These are the
                forgotten women whose plight has been omitted from the public
                discourse on abortion rights and slipped through the cracks of
                our compassion. */}
              </p>
              <p className={style.bannerHeading2}>
                These women need and deserve our support.
                <p>
                  <span> Their lives matter, too!</span>
                </p>
              </p>
              <div className={style.bannerbutton}>
                <Link to="/donate-to-us/">
                  <button>Stand With Us</button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Action />
      <Neighbour></Neighbour>
      <Mission></Mission>
      <CardPage />
      <div className={style.standcontainer}>
        <img className="w-100" src="/still.jpg" alt="banner" />
        <div className={style.standtext}>
          <h1>I Stand With Them</h1>

          <a href="/letter/">
            {" "}
            <p>Letter from the Founder</p>{" "}
          </a>
        </div>
      </div>

      {/* <CenterList centerList={centerList} showfilter={false} /> */}

      {/* <Movement></Movement> */}
      <Newsletter></Newsletter>
    </>
  );
};

export default ForgottenWomen;
