import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import style from "./home.module.css";
import { AiOutlinePlayCircle } from "react-icons/ai";
// import { centerList } from "./dummyData";
import { useLocation, useNavigate } from "react-router-dom";
// import { baseUrl } from "../../utils/api";
import ReactPlayer from "react-player/lazy";

// import { State } from "country-state-city";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
import { useDispatch } from "react-redux";
import { searchCenters } from "../../apis/CenterApis";
import { fileBaseUrl } from "../../utils/api";

const CenterList = ({ centerList, searchCenter, showfilter }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showNext, setShowNext] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  // const [isdata, setIsData] = useState(false);
  
  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength) + "...";
    }
  }
 

  const filesData = (value) => {
    const addfileType = value?.map((file) => {
      const fileExtension = file?.media?.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      // const videoExtensions = ["mp4", "avi", "mov", "mkv"];
      const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

      if (imageExtensions.includes(fileExtension)) {
        file.type = "image";
      } 
      
      
      else if (youtubePattern.test(file.media)) {
        file.type = "youtube";
      }
      else {
        file.type = "unsupported";
      }
      // setIsData(true);
      return file;
    });
    return addfileType;
  };

  const fetchSearchCenter = () => {
    dispatch(
      searchCenters({
        centerName: searchCenter,
        // country: selectedCountry,
        // state: selectedState,
        page: page,
        limit: limit
      })
    ).then((res) => {
      if (res) {
        setFiles(filesData(res?.centersList));
        setPage(res?.currentPage);
        if (res?.currentPage < res?.totalPages) {
          setShowNext(true);
        } else {
          setShowNext(false);
        }
      }
    });
  };

  useEffect(() => {
    setFiles(filesData(centerList));
  }, [centerList]);

  useEffect(() => {
    if (!searchCenter) {
      setFiles(filesData(centerList));
    }
  }, [searchCenter]);

  useEffect(() => {
    if (showfilter && searchCenter) {
      fetchSearchCenter();
    }
  }, [showfilter]);


  const handlejanet = () => {
    const id = "66f2a41ba8462a390b54d2b4"; // Replace with the actual ID
    navigate(`/donation/${id}`);
  };
  const handleClick = (data) => {
    // navigate("/donation",  { state: data });
    navigate(`/donation/${data.id ? data.id : data._id}`);

    localStorage.setItem("centerId", data.id ? data.id : data._id);
  };
  const handlePlay = (url) => {
   setVideoUrl(url);
    
    setShowVideoPlayer(true)
  };

  const handleShowMore = () => {
    dispatch(
      searchCenters({
        centerName: searchCenter,
        // country: selectedCountry,
        // state: selectedState,
        page: page + 1,
        limit: limit
      })
    ).then((res) => {
      if (res) {
        setFiles(filesData(res?.centersList));
        setPage(res?.currentPage);
        if (res?.currentPage < res?.totalPages) {
          setShowNext(true);
        } else {
          setShowNext(false);
        }
      }
    });
  };
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return `, ${text}`;
    }
    return `, ${text.substr(0, maxLength) + "..."}`;
  }
  function getYouTubeVideoId(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }
  
 
  return (
    <div>
      <div className={style.maincentercontainer} style={{}}>
        <div className={style.dashboard_row}>
          <div className={style.centerBox}>
           
            <div className="container">
              <h1 className={style.centerlistheading}>Featured Projects</h1>
              {/* <div className={style.maincenterbox}>
                <Row
                  className="justify-content-center"
                 
                >
                  <Col md={6} className={style.bandata}>
                    <div className={style.centercol}>
                      <div className={`${style.centerheading}`}>
                        <h1> In the Spotlight </h1>
                        <p>Waller Pregnancy Care Center in Waller, Taxes</p>
                      </div>
                      <div className={`  ${style.centerheading2}`}>
                        <p>
                          Hi, I am Janet Lemuel, the founder of WTL Pregnancy
                          Center. Twenty-one years ago, as a nurse on the
                          campus of Prairie View A&M, I started this center out
                          of my house after having so many pregnant young girls
                          came to me scared and alone-wanting to avoid abortion
                          but unsure of what to do.
                        </p>
                        <p>
                          Over the years, we've touched the lives of hundreds
                          and hundreds of young women and their partners by
                          helping them make a choice they're now proud of. Some
                          have come back and worked as volunteers out of
                          gratitude. All but a few of my staff are volunteers.
                          Without them, we would not exist.
                        </p>
                        <p>
                          Today, the vision is to expand our services into a
                          medical mobile clinic in Waller County and surrounding
                          areas to reach women who long for an alternative to
                          abortion in addition to providing prenatal support.
                        </p>
                        <p>
                          This poor rural community is without the resources
                          many of us take for granted. Your contribution will be
                          greatly appreciated and go a long way in helping us
                          provide services free of charge to make difference in
                          the lives of so many in our community.
                        </p>
                        <p>Thank You & God bless</p>
                        <p>Janet</p>
                      </div>
                    </div>
             
                  </Col>

                  <Col md={6} className={style.bandata}>
                    <div className="text-center">
                      <div className={`${style.centerbox}`}>
                     
                        <img
                          src="/Buildings.jpg"
                          alt="building"
                      
                          style={{
                            width: "68%",
                            cursor: "pointer",
                        
                          }}
                          onClick={() =>
                            handlePlay(
                              "https://www.youtube.com/embed/3G3rnMfHtzg?si=5JX4mVhkFPrwubi1"
                            )
                          }
                        />
                  
                        <img
                          style={{
                            cursor: "pointer"
                         
                          }}
                          alt="aplay"
                          src="/aplayblue.svg"
                          className="position-absolute top-50 start-50 translate-middle"
                          onClick={() =>
                            handlePlay(
                              "https://www.youtube.com/embed/3G3rnMfHtzg?si=5JX4mVhkFPrwubi1"
                            )
                          }
                        />

                
                      </div>
                    </div>
                    <Col md={12}>
                      <div className={style.helpbutton}>
                        <button onClick={handlejanet}>Let's Help Janet</button>
                      </div>
                    </Col>
                   
                  </Col>
                </Row>

              </div> */}
            </div>

            <div className="container">
              <Row className="m-0 p-0">
                {files
                  ?.filter((data) => data.description)              
                  .map((data, index) => (
                    <Col className="p-3 " key={index} lg={4} md={6} sm={6}>
                      <div className={`${style.centerCard}`}>
                        <div className="position-relative image-container" style={{height:"150px"}}>
                          {data?.type === "unsupported" && (
                            <img
                              className={`${style.fixedImage} card_image_banner`}
                              src={`/defaultCenter.png`}
                              alt="center"
                            />
                          )}
                          {data?.type === "image" && (
                            <img
                              className={`${style.fixedImage} card_image_banner`}
                              src={`${fileBaseUrl}${data.media}`}
                              alt="center"
                            />
                          )}
                   
                          {data?.type === "youtube" && (
                            <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(data.media)}`}
                            frameborder='0'
                            allow='encrypted-media'
                            allowfullscreen
                            title='video'
                         width="100%"
                         height="100%"
                          />
                          
                         
                          )}
                          <div
                            class="overlay"
                            onClick={() =>
                              ( data?.type === "youtube") && handlePlay(data?.media)
                            }></div>
                      
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(data)}>
                          <div
                            className="my-2"
                            style={{
                              fontSize: "17px",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                              lineHeight: "30px",
                            }}>
                            {data.centerName}
                          </div>
                          <div
                            style={{
                              color: "#191919",
                              minHeight: "120px",
                              maxHeight: "120px",
                              WebkitLineClamp: 4,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: "17px",
                              lineHeight: "30px",
                              fontWeight: "400",
                              // whiteSpace:"nowrap"
                            }}>
                            {truncateString(data.description, 120)}
                          </div>
                        </div>
                        <div
                          className=" d-flex justify-content-between align-items-center "
                          style={{ marginTop: "28px" }}>
                            <Col md={6}>
                            <div className={style.location}>
                            {" "}
                            {data.country === "United States"
                              ? "US"
                              : data.country}
                            {data.state ? truncateText(data.state, 12) : ""}{" "}
                          </div>
                            </Col>
                            <Col md={6}>
                            <Button
                            variant="primary"
                            size="sm"
                            className="authBlubtn px-3 rounded-0"
                            onClick={() => handleClick(data)}>
                            Donate Now{" "}
                          </Button>
                            </Col>
                          
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
            {showNext && (
              <div className="text-center">
                <Button variant="light" onClick={handleShowMore}>
                  Show More
                </Button>
              </div>
            )}
            {location?.pathname !== "/search-center" && files?.length !== 0 && (
              <div className="text-center ">
                <p
                  onClick={() => navigate("/search-center")}
                  className={style.centerbrowse}>
                  {" "}
                  Browse all
                </p>
                
              </div>
            )}
          </div>
        </div>
      </div>
      <VideoPlayer
        showVideoPlayer={showVideoPlayer}
        setShowVideoPlayer={setShowVideoPlayer}
        videoUrl={videoUrl}
        // isdata={isdata}
      />
    </div>
  );
};

export default CenterList;
