import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import {  Form, Button, Spinner } from "react-bootstrap";
import style from "./donation.module.css";
import { IoClose } from "react-icons/io5";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { createPrayer } from "../../apis/paymentApis";

const Pray = (id) => {
  console.log("id....",id)
  const [isopen, setIsOpen] = useState(false);
  
  const [disableButton, setDisableButton] = useState(false);
  const handleopen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  

  const dispatch = useDispatch();

  return (
    
    <>
   
            <Modal show={isopen}  size="lg"   backdrop="true" onHide={handleClose} > 
                <div className={style.closeicon}>
            <IoClose onClick={handleClose}></IoClose>  
            </div>
            <div className={style.praymain}>
             
              <h1 className={style.prayhead}>Pray</h1>
              <p className={style.praypara}>
                Send an encouraging message or prayer to the campaign owner. Let
                them know you are praying for them. The prayer of a righteous
                person is powerful and effective. James 5:16
              </p>
              <Formik
                  initialValues={{
                    FirstName: "",
                    LastName:"",
                    email: "",
                    message: "",
                    centerId:""
                  }}

                  validationSchema={Yup.object().shape({
                    FirstName: Yup.string().required(" First Name is required"),
                    LastName: Yup.string().required(" Last Name is required"),
                    message: Yup.string().required(" Message is required"),
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                  
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting, resetForm }
                  ) => {
                    setDisableButton(true);
                    try {
                 let centerId=id;
                 values.centerId=centerId
                      setStatus({ success: false });
                      setSubmitting(false);
                      dispatch(createPrayer({value:values})).then((res) => {
                        if (res) {
                          resetForm();
                          setIsOpen(false)
                          setDisableButton(false);
                        }
                        setDisableButton(false);
                      });
                    } catch (err) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                      setDisableButton(false);
                    }
                  }}
                
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                         <Form.Group
                        className="mb-4"
                        controlId="formBasicmessage"
                      >

                        <Form.Control
                          as="textarea"
                          rows={6}
                          className= {`${style.innerinputtext}  ${touched.message && errors.message ? "is-invalid" : ""}`}
                          value={values.message}
                          placeholder="Prayers written using cursing or hate will be deleted automatically" 
                          name="message"
                          onBlur={handleBlur }
                          onChange={handleChange}
                          error={Boolean(touched.message && errors.message)}
                        />
                        {touched.message && errors.message && (
                          <Form.Text className=" text-danger">
                            {errors.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className={style.maininput}
                      >
                      <Form.Group
                    className={`${style.inputfirst} mb-4`}
                        controlId="formBasicName"
                      >
                        <Form.Control
                          type="text"
                        className= {`${style.innerinputfirst}  ${touched.FirstName && errors.FirstName ? "is-invalid" : ""}`}

                          value={values.FirstName}
                          placeholder="FirstName"
                          name="FirstName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.FirstName && errors.FirstName)}
                        />
                        {touched.FirstName && errors.FirstName && (
                          <Form.Text className=" text-danger">
                            {errors.FirstName}
                          </Form.Text>
                        )}
                      </Form.Group>

                      <Form.Group
                          className={`${style.inputlast} mb-4`}
                        controlId="formBasicName"
                      >
                        <Form.Control
                          type="text"
                          className= {`${style.innerinputlast}  ${touched.LastName && errors.LastName ? "is-invalid" : ""}`}
                          value={values.LastName}
                          placeholder="LastName"
                          name="LastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.LastName && errors.LastName)}
                        />
                        {touched.LastName && errors.LastName && (
                          <Form.Text className=" text-danger">
                            {errors.LastName}
                          </Form.Text>
                        )}
                      </Form.Group>
                      </div>
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        
                        <Form.Control
                         className={`${style.innerinputemail}  ${touched.email && errors.email ? "is-invalid" : ""}`}
                          type="email"
                          value={values.email}
                          placeholder="Email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                        />
                        {touched.email && errors.email && (
                          <Form.Text className=" text-danger">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                     
                      <Button
                        className={`${style.praybutton} w-100`}
                        variant="primary"
                        type="submit"
                        disabled={disableButton}
                      >
                        Pray
                        {disableButton && (
                      <Spinner
                        className="ms-3"
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                      </Button>
                    </Form>
                  )}
                </Formik>
                </div>
            </Modal>
            <div className={style.donationbuttoncontainermain}>
                          <div className={style.smallscreenabutton}>
                            <button onClick={handleopen}>
                            Pray
                            </button>
                          </div>
                        </div>

                        
    </>
  );
};

export default Pray;

