import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import { Col, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { paypalPayment } from "../../apis/paymentApis";
import { toast } from "react-toastify";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useGetUser from "../../customHook/useGetUser";
import { Helmet } from "react-helmet";
import { getToken } from "../../utils/api";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import Pray from "./pray";
const SpecialDonationPage = () => {
  useGetUser();

  const navigate = useNavigate();

  const [amount, setSelectedAmount] = useState(""); // State for the selected amount radio button
  const [selectedFrequency, setSelectedFrequency] = useState("month"); // State for the selected frequency radio button
  const [id, setId] = useState("");
  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value); // Update the selected amount
  };

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value); // Update the selected frequency
  };

  const clearamount = amount.replace(/,/g, "");

  localStorage.setItem("amount", clearamount);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check this out!",
          text: "I found this amazing content. Have a look!",
          url: window.location.href, // Current page URL
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Sharing is not supported on your browser.");
    }
  };

  useEffect(() => {
    const token = getToken();

    if (token) {
      const decoded = jwtDecode(token);
      setId(decoded?.sub);
    }
  }, []);

  const dispatch = useDispatch();

  const books = [
    {
      id: "book2",
      name: "The Blueprint to Ending Abortion in the Church",
      file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/The-Blueprint-to-Ending-Abortion-in-the-Church-I-Stand-With-the-Forgotten-Women-.pdf",
      img: "/blueprint.jpg",
      description: `A Crucial Resource for Crucial Times
Research shows abortion is prevalent in the church—54% of women who have had one identify as Catholic or Protestant, and 40% attended church regularly at the time. Yet, 76% felt their church had no ministry to turn to.Abortion is one of the most divisive issues, demanding pastoral leadership. This resource equips pastors to address its challenges with biblical integrity and practical insights. Silence has allowed false doctrine to spread, but by shifting the focus from pro-life vs. pro-choice to reducing abortion's impact, we can build unity.Modeled after God's response to the world's most well-known unplanned pregnancy—the birth of Christ—this approach moves beyond saving heartbeats to fostering Pro Abundant Life, breaking the cycle of unplanned pregnancies and leading lives heavenward.`,
    },
    {
      id: "book1",
      name: "Brainwashed",
      file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/Brainwashed-I-Stand-With-the-Forgotten-Women.pdf",
      img: "/brainwashed.jpg",
      description: `The Deception of Pro-Choice Propaganda
What do pro-choice propaganda and Adolf Hitler’s mass genocide have in common? More than you might think. Both used deceit, manipulation, and repetition of lies to reshape public opinion.Hitler convinced millions that exterminating Jews was a necessary evil, spreading false narratives to justify mass murder. His propaganda chief, Joseph Goebbels, famously said, “If you repeat a lie often enough, people will believe it.”For 50 years, abortion activists have used similar tactics—emphasizing choice while dehumanizing the preborn—justifying the loss of 60 million innocent lives. `,
    },
  ];

  const selectedBookLinks = books.map((book) => ({
    name: book.name,
    link: book.file,
    id: book.id,
  }));

  const bookIds = selectedBookLinks.map((book) => book.id);
  const handleDonate = () => {
    if (!amount) {
      toast.error("Please select a  amount for proceed the payment"); // Show error toast if no amount is selected
      return;
    }
    localStorage.setItem("frequency", selectedFrequency);
    localStorage.setItem("book", JSON.stringify(selectedBookLinks));
    navigate("/information/");
  };

  return (
    <>
      <Helmet>
        <title>Donate-to-us - I Stand With The Forgotten Women</title>
        <meta
          name="description"
          content="Support our mission by choosing a donation amount and frequency. Help ensure those in need are not forgotten. Your generosity matters!"
        />
        <meta
          name="keywords"
          content="Donation amount,Custom donation,Donation frequency,One-time donation,Monthly donation,Checkout,Thank you,Financial contribution,Support,Charitable giving"
        />
      </Helmet>
      <div className={style.donationcontainer}>
        <div className="container">
          <div className={style.inforcol}>
            <Row>
              <Col md={12} xl={7} lg={12}>
                <div className="p-3">
                  <div className={style.paymentForm}>
                    <div className={style.paymentHead}>
                      Choose A Donation Amount
                    </div>
                    <div className={style.donationpayment}>
                      <div className={style.paymentcontainer}>
                        <Row>
                          <Col md={6}>
                            <div
                              className={
                                amount === "25"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="25"
                                      checked={amount === "25"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$25</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div
                              className={
                                amount === "40"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="40"
                                      checked={amount === "40"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$40</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div
                              className={
                                amount === "50"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="50"
                                      checked={amount === "50"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$50</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div
                              className={
                                amount === "70"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="70"
                                      checked={amount === "70"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$70</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div
                              className={
                                amount === "1000"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="100"
                                      checked={amount === "100"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$100</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div
                              className={
                                amount === "200"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="200"
                                      checked={amount === "200"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$200</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className={style.amountdonation}>
                        <div className={style.labellink}>
                          Enter a custom donation amount
                        </div>

                        <div className={style.customAmountInput}>
                          <span className={style.currency}>$</span>
                          <input
                            type="text"
                            value={amount}
                            placeholder="Enter custom amount"
                            onChange={(e) => setSelectedAmount(e.target.value)}
                            className={style.inputboxtext}
                          />
                        </div>
                      </div>
                      <h3>Choose a donation frequency</h3>
                      <div className={style.donateradio}>
                        <div
                          className={
                            selectedFrequency === "1"
                              ? style.selectedLabel2
                              : style.labeldonate
                          }>
                          <div className={style.donationlabel}>
                            <label className={style.labelcontent}>
                              <input
                                type="radio"
                                name="frequency"
                                value="1"
                                checked={selectedFrequency === "1"}
                                onChange={handleFrequencyChange}
                              />

                              <span>OneTime</span>
                            </label>
                          </div>
                        </div>

                        <div
                          className={
                            selectedFrequency === "month"
                              ? style.selectedLabel2
                              : style.labeldonate2
                          }>
                          <div className={style.donationlabel}>
                            <label className={style.labelcontent}>
                              <input
                                type="radio"
                                name="frequency"
                                value="month"
                                checked={selectedFrequency === "month"}
                                onChange={handleFrequencyChange}
                              />

                              <span>Monthly</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className={style.donationbuttoncontainer}>
                        <div className={style.smallscreenabutton}>
                          <button onClick={handleDonate}>
                            <img src="/logosstripe.png"></img>
                          </button>
                        </div>
                      </div>
                      <div className={style.donationbuttonpaypalcontainer}>
                        <div className={style.smallscreenabutton}>
                          <PayPalScriptProvider
                            options={{
                              "client-id":
                                "Adpk1iHPoLLJks3J32GLO598fGKUobHCZWHn89fhQ1Xp9_TgO_4kYrSE9VHKMQTLCHkd7emdnl7LvxmM",

                              "enable-funding": "paypal", // Only show PayPal as a funding option
                              "disable-funding": "card", // Disable debit/credit card option
                            }}>
                            <PayPalButtons
                              style={{
                                color: "white",
                                shape: "pill",
                              }}
                              className={style.paypalbutton}
                              createOrder={(data, actions) => {
                                return actions.order.create({
                                  purchase_units: [
                                    {
                                      amount: {
                                        value: amount, // Set the payment amount here
                                      },
                                    },
                                  ],
                                });
                              }}
                              onApprove={(data, actions) => {
                                return actions.order
                                  .capture()
                                  .then(function (details) {
                                    const transactionId = details.id;
                                    const email = details.payer.email_address;

                                    // Store email in localStorage
                                    if (email) {
                                      localStorage.setItem("email", email);
                                    }
                                    // Save transaction in database
                                    dispatch(
                                      paypalPayment({
                                        amount: amount,
                                        email: email,
                                        id: id,
                                        status: details.status,
                                        books: selectedBookLinks,
                                      })
                                    )
                                      .then(() => {
                                        // After successful database save, navigate to success page
                                        navigate(
                                          `/success?success=true&transactionId=${transactionId}&bookId=${bookIds}`
                                        );
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error saving transaction:",
                                          error
                                        );
                                        toast.error(
                                          "Payment was successful, but failed to save transaction."
                                        );
                                      });
                                  });
                              }}
                              onError={(err) => {
                                if (!amount) {
                                  toast.error("Please select an amount");
                                } else {
                                  toast.error("An error occured");
                                }
                              }}
                            />
                          </PayPalScriptProvider>
                        </div>
                      </div>
                      <div className={style.donationbuttoncontainer}>
                        <div className={style.smallscreenabutton}>
                          <button onClick={handleShare}>
                            Help Spread the Word
                          </button>
                        </div>
                      </div>
                      <Pray />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={12} xl={5} lg={12} xs={12}>
                <Row>
                  <h1 className={style.headdonate}>
                    Free With Donation
                    {/* Donate now to get it free */}
                  </h1>

                  {books.map((book) => (
                    <Col key={book.id} xl={12}>
                      {book.id == "book1" && (
                        <h2 className={style.specialbooksubhead}>
                          Receive this as a special gift for your support
                        </h2>
                      )}
                      <Row>
                        <Col xl={5} md={3} lg={4}>
                          <img
                            src={book.img}
                            alt={book.name}
                            className="w-100"
                          />
                          {book.id === "book1" ? (
                            <p className={style.bookundertext}>
                              It’s almost as if they got their hands on a copy
                              of Hitler’s political manifesto, How to Brainwash
                              the Masses into Becoming Loyal Defenders of a
                              Morally Indefensible Public Policy.{" "}
                            </p>
                          ) : book.id === "book2" ? (
                            <p className={`${style.bookundertext} m-0`}>
                              As God-honoring as our Pro-Life mission has been,
                              we've been unable to move the needle in breaking
                              the intergenerational cycle of unplanned
                              pregnancies that leads to the risk of abortion.
                            </p>
                          ) : null}
                        </Col>
                        <Col
                          xl={7}
                          md={9}
                          lg={8}
                          className={style.contentdonate}>
                          <p className={style.bookparadonate}>
                            {book.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                {/* <div>
                  <Row>
                  <h1 className={style.headdonate}>
                      Donate now to get it free
                    </h1>
                    <Col xl={4} md={3} lg={4}>
                      <img className="w-100" src="/brainwashed.jpg" />
                    </Col>

                    <Col xl={8} md={9} lg={8} className={style.contentdonate}>
                      <p className={style.bookparadonate}>
                        Though often linked to Hitler, Stalin (17M deaths), Mao
                        Zedong (45M), Koresh, and Jones all used mind control to
                        erase the stigma of mass murder. But, if there was an
                        award given out for the propaganda campaign that
                        influences the most and kills the most in the history of
                        mankind, abortion rights activists would be the runaway
                        winner.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col xl={4} md={3} lg={4}>
                      <img className="w-100" src="/jimmycarter.jpg" />
                    </Col>

                    <Col xl={8} md={9} lg={8} className={style.contentdonate}>
                      <p className={style.bookparadonate}>
                        Speaking about a revered man wasn’t easy. For years, I
                        sought to interview the most well-known pro-choice
                        Christian. To understand this perspective, I researched
                        extensively, seeking how one can follow Christ while
                        embracing a pro-choice view.I address that in my final
                        commentary. However, I was left with more questions than
                        when I began.
                      </p>
                    </Col>
                  </Row>
                </div> */}

                <div className={style.donatespecialthank}>
                  <h1>Thank You For Proving They’re Not Forgotten</h1>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialDonationPage;
