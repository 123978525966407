import React, { useEffect, useState } from "react";
import style from "./donation.module.css";

import { baseUrl, getToken } from "../../utils/api";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

const DonationSucess = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState([]);

  const [loading, setLoading] = useState(false);
  const donationAmount = Number(localStorage.getItem("amount")) || 0;
  const [searchParams] = useSearchParams();

const navigate = useNavigate();
// const donationAmount =51;
  let maxSelectableBooks = 2; // Default: Only one book
  // if (donationAmount > 25) maxSelectableBooks = 2;
  // if (donationAmount > 50) maxSelectableBooks = 3;
  // if (donationAmount > 75) maxSelectableBooks = 5;



  const books = [
    { id: "book1", name: "Brainwashed", file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/Brainwashed-I-Stand-With-the-Forgotten-Women.pdf", img: "/brainwashed.jpg" },
    { id: "book2", name: "The Blueprint to Ending Abortion in the Church", file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/The-Blueprint-to-Ending-Abortion-in-the-Church-I-Stand-With-the-Forgotten-Women-.pdf", img: "/blueprint.jpg" },
    { id: "book3", name: "A Christian Parents Guide to Sexuality Education", file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/A-Christian-Parents-Guide-to-Sexuality-Education.pdf", img: "/parentsguide.jpg" },
    { id: "book4", name: "The Shameful Legacy of Jimmy Carter", file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/The-Shameful-Legacy-of-Jimmy-Carter.pdf", img: "/jimmycarter.jpg" },
    { id: "book5", name: "Stuff Our Parents Should’ve Told About Sex!", file: "https://blog.istandwiththeforgottenwomen.org/wp-content/uploads/2025/02/Stuff-Our-Parents-Shouldve-Told.pdf", img: "/stuffourparents.jpg" },
  ];

  const handleBookSelection = (bookId) => {
    if (selectedBooks.includes(bookId)) {
      // If already selected, remove it
      setSelectedBooks(selectedBooks.filter((id) => id !== bookId));
    } else {
      // Allow replacing selection when the limit is reached
      if (selectedBooks.length >= maxSelectableBooks) {
        setSelectedBooks([bookId, ...selectedBooks.slice(0, maxSelectableBooks - 1)]);
      } else {
        setSelectedBooks([...selectedBooks, bookId]);
      }
    }
  };
  let email;
  const token=getToken();

  console.log("token",token);
  if(token){
   console.log("Entertoken")
   const decoded = jwtDecode(token);
   console.log("decoded",decoded)
    email=  decoded?.email;


  }
  else{
    email=localStorage.getItem("email")

  }

  useEffect(() => {
    const txnId = searchParams.get("transactionId");
    const bookId=searchParams.get("bookId")
    console.log("tnx",txnId)
    const txnStatus = searchParams.get("success");
    console.log("ynx",txnStatus)
  //  const paymentsucess= localStorage.getItem("paymentSuccess")
    // Open modal only if the user has made a successful payment
    if (txnId && txnStatus=='true' && donationAmount>0 && !bookId) {
      console.log("entertnx")
      setShowModal(true);
    }
  }, []);

localStorage.removeItem("book");
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBooks([]); // Reset selected books when modal is closed
  };
  


  const handleSubmit = async () => {
    if (selectedBooks.length === 0) {
      toast.error("Please select at least one book.");
      return;
    }

    setLoading(true);
    try {
      const selectedBookLinks = books
        .filter((book) => selectedBooks.includes(book.id))
        .map((book) => ({ name: book.name, link: book.file }));

      const response = await axios.post(`${baseUrl}/payment/sendemail`, {
        email:email,
        books: selectedBookLinks,
      });

      if (response.data.success) {
        toast.success("E-book links sent to your email!");
        setShowModal(false);
        localStorage.removeItem("amount")
        localStorage.removeItem("email")
        localStorage.removeItem("paymentSuccess")
     
     
      } else {
        toast.error("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred. Please try again.");
    }
    setLoading(false);
  };

  return (
    <>
     <Helmet>
    <title>Success - I Stand With Forgotten Women</title>
    <meta name="description" content="We appreciate your generous donation! Your support is vital in helping forgotten women. If you have questions, feel free to reach out. Together, we can take action." />
    <meta name="keywords" content="Thank you,Standing with us,Donation impact,Make a difference,Support women,Forgotten women,Action now,Giving back,Reach out,Community support"/>
  </Helmet>
      <div className={style.donationsucesscontainer}>
        <div className="container">
        {/* <img className="w-100" src="/Still.svg" alt="banner" /> */}
        <div className={style.donationcontainertext}>
          <h1>Thank you for Standing with Us</h1>
          {/* <h2>Mike Goss</h2> */}
          <p>No one wants to be forgotten</p>

          {/* <a onClick={()=>setShowModal(true)} className={style.ebooklink} >
          Select your free e-book
          </a> */}

        </div>
        </div>
      </div>

      <div className={style.donationthankcontainer}>
        <div className="container">
          
        <div className={style.thankcontainertextp}>
        <p>
        Thank you so much for giving. Your donation is helping to make a difference. If you have any questions, please don’t hesitate to reach out.
          </p>

        
                {/* <p>
            Thank you so much for giving. We’ve sent a receipt for your donation
            to {email} If you have any questions, please
            don’t hesitate to reach out.
          </p> */}
          </div>

        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Your Free E-Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Choose from the available e-books below:</p>
          <p><strong>Donation Amount: ${donationAmount}</strong></p>
          <p>You can select up to {maxSelectableBooks} book(s).</p>

          <Row>
            {books.map((book) => (
              <Col key={book.id} md={4} className="text-center">
            <label htmlFor={book.id} style={{ cursor: 'pointer' }}>
          <img src={book.img} alt={book.name} className="w-100" />
          <Form.Check 
            type="checkbox"
            label={book.name}
            id={book.id}
            checked={selectedBooks.includes(book.id)}
            onChange={() => handleBookSelection(book.id)}
            className={style.checkboxbook}
          />
        </label>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
        <div className={style.modalfooter}>
    <a target="_blank" href="https://blog.istandwiththeforgottenwomen.org/product/blueprint-to-ending-abortion-in-the-church/">
    Get Your Paperback Copy of "The Blueprint to Ending Abortion" Click Here to Order!     </a>
    <button onClick={handleSubmit} disabled={loading} className={style.modalbutton}>
      {loading ? "Sending..." : "Send Books"}
    </button>
  </div>

        
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DonationSucess;