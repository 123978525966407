import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";

import { fileBaseUrl } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import {
  oneTimePayment,
  createSubscription,
  getRecentTransaction,
  AdminPayment,
  createAdminSubscription,
} from "../../apis/paymentApis";
import { useDispatch } from "react-redux";
import { getToken } from "../../utils/api";
import { toast } from "react-toastify";
import TransactionStatus from "../../modals/transaction/TransactionStatu";
import { getCenterById, getComments } from "../../apis/CenterApis";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { paypalPayment } from "../../apis/paymentApis";
import {
  getTotalTransactionandPercentage,
  fetchConnectedAccount,
} from "../../apis/paymentApis";
import useGetUser from "../../customHook/useGetUser";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";
import Pray from "./pray";

const Donation = () => {
  const token = getToken();
  const [searchParams] = useSearchParams();
  useGetUser();
  const location = useLocation();

  const dispatch = useDispatch();
  const [centerData, setCenterData] = useState(null);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  // const [isdata, setIsData] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);

  const [showTransactionStatus, setShowTransactionStaus] = useState(false);
  const [donationList, setDonationList] = useState(null);
  const [centerDetail, setCenterDetail] = useState();
  const [usercomment, setUserComment] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState();
  const navigate = useNavigate();
  const fetchRecentTransaction = (id) => {
    if (id) {
      dispatch(getRecentTransaction({ id })).then((res) => {
        if (res?.transactions) setDonationList(res.transactions);
      });
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: window.location.href, // Current page URL
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Sharing is not supported on your browser.");
    }
  };

  useEffect(() => {
    const success = searchParams.get("success");
    if (success === "true") {
      setShowTransactionStaus(true);
    }
  }, [searchParams]);

  const filesData = (value) => {
    const fileExtension = value?.media?.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    // const videoExtensions = ["mp4", "avi", "mov", "mkv"];
    const youtubePattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    console.log("value", value?.media);
    if (imageExtensions.includes(fileExtension)) {
      value.type = "image";
    } else if (youtubePattern.test(value.media)) {
      console.log("valueenter");
      value.type = "youtube";
    } else {
      value.type = "unsupported";
    }

    return value;
  };

  // Extracting the ID manually from the pathname
  const path = location.pathname;
  const id = path.split("/").pop();

  useEffect(() => {
    if (location.state) {
      fetchRecentTransaction(id);
      setCenterData(location.state);
    }
    if (!location.state) {
      dispatch(getCenterById({ id })).then((res) => {
        if (res?.centerData) {
          fetchRecentTransaction(id);
          setCenterData(filesData(res?.centerData));
        }
      });
    }
    dispatch(getTotalTransactionandPercentage({ id: id })).then((res) => {
      if (res?.result) {
        setCenterDetail(res?.result);
      }
    });
    // setIsData(true);
  }, [location]);

  useEffect(() => {
    console.log("Enterusercomment");
    dispatch(getComments({ id: id })).then((res) => {
      console.log("enter.....", res);
      if (res) {
        console.log("Enetrifcoment");
        setComments(res);
      }
    });
    // setIsData(true);
  }, []);

  useEffect(() => {
    const fetchConnect = async () => {
      try {
        const accountData = await fetchConnectedAccount({ id: id });
        console.log("Account", accountData);
        setConnectedAccount(accountData);
      } catch (err) {
        console.error("Error fetching connected account:", err);
      }
    };

    if (id) {
      fetchConnect();
    }
  }, [id]);
  const handleDateConversion = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const formattedDateTime = dateObject.toLocaleDateString();
    return formattedDateTime;
  };
  const handlePlay = (url) => {
    setVideoUrl(url);
    setShowVideoPlayer(true);
  };
  const [amount, setSelectedAmount] = useState(""); // State for the selected amount radio button
  const [selectedFrequency, setSelectedFrequency] = useState("month"); // State for the selected frequency radio button
  const [connectedAccount, setConnectedAccount] = useState(null);
  const [Id, setId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value); // Update the selected amount
  };

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value); // Update the selected frequency
  };

  useEffect(() => {
    if (token) {
      console.log("Entertoken");
      const decoded = jwtDecode(token);
      console.log("decoded", decoded);
      setId(decoded?.sub);
      setEmail(decoded?.email);
      setIsLoggedIn(true);
    }
  }, []);

  const handleDonate = () => {
    if (!amount) {
      toast.error("Please select a  amount for proceed the payment");
      return;
    }
    if (!email) {
      toast.error("Please enter the email address");
      return;
    }

    const clearamount = amount.replace(/,/g, "");

    if (!isNaN(clearamount) && Number(clearamount) >= 0) {
      console.log("enter");
      if (selectedFrequency === "1" && connectedAccount) {
        console.log("ente rinto");
        dispatch(
          oneTimePayment({
            amount: clearamount,
            centerId: centerData?._id ? centerData._id : centerData.id,
            usercomment: usercomment,
            email: email,
            _id: Id,
          })
        ).then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
          }
        });
      } else if (selectedFrequency == "month" && connectedAccount) {
        dispatch(
          createSubscription({
            amount: amount,
            interval: "month",
            centerId: centerData?._id ? centerData._id : centerData.id,
            usercomment: usercomment,
            email: email,
            _id: Id,
          })
        ).then((res) => {
          if (res?.url) {
            window.location.href = res?.url;
          }
        });
      } else if (!connectedAccount && selectedFrequency === "1") {
        dispatch(
          AdminPayment({
            amount: amount,
            email: email,
            id: Id,
            centerId: centerData?._id ? centerData._id : centerData.id,
          })
        ).then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
          }
        });
      } else {
        dispatch(
          createAdminSubscription({
            amount: amount,
            interval: "month",
            email: email,
            id: Id,
            centerId: centerData?._id ? centerData._id : centerData.id,
          })
        ).then((res) => {
          if (res?.url) {
            window.location.href = res?.url;
          }
        });
      }
    } else {
      toast.error("Invalid amount. Please enter a valid number.");
      return;
    }
  };

 // if (!isLoggedIn) {
  localStorage.setItem("email", email);
  localStorage.setItem("amount",amount)

// }


  function getYouTubeVideoId(url) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  console.log("Comments", comments);
  return (
    <>
      <Helmet>
        <title>
          {centerData?.id != "66f2a41ba8462a390b54d2b4" &&
          centerData?.centerName
            ? `Donation - ${centerData.centerName} - I Stand With The Forgotten Women`
            : "Donation - Janet - I Stand With The Forgotten Women"}
        </title>
        <meta
          name="description"
          content="Join us in empowering women and families facing unplanned pregnancies. Your donation helps provide vital resources, support, and alternatives to abortion through our network of pregnancy care centers. Together, we can create a positive impact in our communities."
        />
        <meta
          name="keywords"
          content="Mobile Medical Unit,Pregnancy Support,Alternatives to Abortion,Community Outreach,Health Essentials,Donation Campaign,Prenatal Care,Empowerment,Fundraising,Education"
        />
      </Helmet>
      <div className={style.mainsection}>
        <div className={style.donationcontainer}>
          <div className="container ">
            <div className={style.maindonation}>
            <div className={`${style.centername}`}>
                      {centerData?.centerName}
                    </div>
                    <div className={style.centerLocation}>
                      {centerData?.address}, {centerData?.city}{" "}
                      {centerData?.state} {centerData?.country}
                    </div>
              <Row>
                <Col md={12} xl={8} lg={12} className="p-0">
                  <div className={style.maincenterform}>
                    {/* <div className={`${style.centername}`}>
                      {centerData?.centerName}
                    </div>
                    <div className={style.centerLocation}>
                      {centerData?.address}, {centerData?.city}{" "}
                      {centerData?.state} {centerData?.country}
                    </div> */}

                    <div>
                      <div
                        className={`position-relative image-container ${style.mainvideocontainer}`}>
                        {centerData?.type === "unsupported" && (
                          <img
                            className="w-100 "
                            src={`/defaultCenter.png`}
                            alt="center"
                          />
                        )}
                        {centerData?.type === "image" && (
                          <img
                            className="w-100 "
                            src={`${fileBaseUrl}${centerData?.media}`}
                            alt="center"
                          />
                        )}

                        {centerData?.type === "youtube" && (
                          <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(centerData.media)}`}
                            frameborder="0"
                            allow="encrypted-media"
                            allowfullscreen
                            title="video"
                            width="100%"
                            height="100%"
                          />
                        )}
                        <div
                          class={`overlay ${style.youvideo}`}
                          onClick={() =>
                            centerData?.type === "youtube" &&
                            handlePlay(centerData?.media)
                          }></div>
                      </div>

                      <div className={style.donatedescription}>
                        {centerData?.description}
                      </div>

                 
                                            <div className={style.donationbuttonmain}>
                                                <Link to="/donate-to-us/">
                                              <button >
                                                Donate Now
                                                {/* Help spread the world */}
                                              </button>
                                              </Link>
                                            </div>
                                     
                      {/* <div className="mt-5 ">
                        <div className={`my-2 ${style.amountdonationn}`}>
                          ${centerDetail?.totalAmount} USD raised of $
                          {centerDetail?.goalAmount?.toLocaleString()}
                        </div>
                        <ProgressBar
                          variant="info"
                          animated
                          now={centerDetail?.percentage}
                        />
                        <div className={`my-2 ${style.headingform}`}>
                          Recent Donations
                        </div>
                        <div className={style.donationtable}>
                          <table className="table m-0">
                            <thead className="thead-dark border-top border-bottom">
                              <tr>
                                <th scope="col">DATE</th>
                               
                                <th scope="col">AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody className={style.noborder}>
                              {donationList?.map((item, index) => (
                                <tr key={index} className="border-none">
                                  <td>
                                    {handleDateConversion(item.createdAt)}
                                  </td>

                                  <td style={{ color: "#06A1D7" }}>
                                    ${item.amount}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      
                        {!donationList && (
                          <div className=" p-5 text-center">
                            <h5> No Data Found </h5>
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
        
                </Col>
                <Col md={12} xl={4} lg={12} >
                
                <div className={style.amountdonationmain}>
                          <div className={style.labellinkmain}>
                            Enter a custom donation amount
                          </div>

                          <div className={style.customAmountInputdonation}>
                            <span className={style.currencydonation}>$</span>

                            <input
                              type="text"
                              value={amount}
                              placeholder="Enter custom amount"
                              onChange={(e) =>
                                setSelectedAmount(e.target.value)
                              }
                              className={style.inputboxtextdonation}
                            />
                          </div>

                          <div className={style.labellinkemailmain}>
                            Enter Email
                          </div>

                          <div className={style.customAmountInput}>
                            <input
                              type="email"
                              placeholder="Email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className={style.informationinputdonationmain}
                              disabled={isLoggedIn} // Disable if logged in
                            />
                          </div>
                        </div>
                  <div className={style.paymentformmaindonation}>
                    <div className={style.paymentFormdonation}>
                      
                      {/* <div className={style.paymentHead}>
                        Choose A Donation Amount
                      </div> */}
                      <div className={style.donationpaymentmain}>
                        {/* <div className={style.paymentcontainer}>
                          <Row>
                            <Col md={6}>
                              <div
                                className={
                                  amount === "25"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="25"
                                        checked={amount === "25"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$25</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div
                                className={
                                  amount === "40"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="40"
                                        checked={amount === "40"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$40</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                              <div></div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div
                                className={
                                  amount === "50"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="50"
                                        checked={amount === "50"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$50</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div
                                className={
                                  amount === "70"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="70"
                                        checked={amount === "70"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$70</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div
                                className={
                                  amount === "100"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="100"
                                        checked={amount === "100"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$100</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div
                                className={
                                  amount === "200"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="200"
                                        checked={amount === "200"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$200</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Form.Group
                          className="my-3"
                          controlId="formBasicCheckbox">
                          <Form.Check
                            className={style.donationcomment}
                            type="checkbox"
                            label="Write us a comment"
                            aria-label="usercomment"
                            value={usercomment}
                            onClick={() => setShowTextArea(!showTextArea)}
                          />
                          {showTextArea && (
                            <textarea
                              className={`${style.commentBox}`}
                              type="textarea"
                              placeholder="Your Comment"
                              aria-label="usercomment"
                              value={usercomment}
                              onChange={(e) => setUserComment(e.target.value)} // Update comment state
                            />
                          )}
                        </Form.Group> */}

                        {/* <div className={style.amountdonation}>
                          <div className={style.labellink}>
                            Enter a custom donation amount
                          </div>

                          <div className={style.customAmountInput}>
                            <span className={style.currency}>$</span>

                            <input
                              type="text"
                              value={amount}
                              placeholder="Enter custom amount"
                              onChange={(e) =>
                                setSelectedAmount(e.target.value)
                              }
                              className={style.inputboxtext}
                            />
                          </div>

                          <div className={style.labellinkemail}>
                            Enter Email
                          </div>

                          <div className={style.customAmountInput}>
                            <input
                              type="email"
                              placeholder="Email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className={style.informationinput}
                              disabled={isLoggedIn} // Disable if logged in
                            />
                          </div>
                        </div> */}

                        <h3>Choose a donation frequency</h3>
                        <div className={style.donateradiomain}>
                          <div
                            className={
                              selectedFrequency === "1"
                                ? style.selectedLabel2main
                                : style.labeldonatemain
                            }>
                            <div className={style.donationlabelmain}>
                              <label className={style.labelcontent}>
                                <input
                                  type="radio"
                                  name="frequency"
                                  value="1"
                                  checked={selectedFrequency === "1"}
                                  onChange={handleFrequencyChange}
                                />

                                <span>OneTime</span>
                              </label>
                            </div>
                          </div>

                          <div
                            className={
                              selectedFrequency === "month"
                                ? style.selectedLabel2main
                                : style.labeldonate2main
                            }>
                            <div className={style.donationlabelmain}>
                              <label className={style.labelcontent}>
                                <input
                                  type="radio"
                                  name="frequency"
                                  value="month"
                                  checked={selectedFrequency === "month"}
                                  onChange={handleFrequencyChange}
                                />

                                <span>Monthly</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className={style.donationbuttoncontainermain}>
                        <div className={style.smallscreenabutton}>
                          <button onClick={handleDonate}>
                            <img src="/logosstripe.png"></img>
                          </button>
                        </div>
                      </div>

                          <div className={style.donationbuttonpaypalcontainermain}>
                                                <div className={style.smallscreenabutton}>
                                                  <PayPalScriptProvider
                                                  className={style.paypalbutton}
                                                    options={{
                                                      "client-id":
                                                        "Adpk1iHPoLLJks3J32GLO598fGKUobHCZWHn89fhQ1Xp9_TgO_4kYrSE9VHKMQTLCHkd7emdnl7LvxmM",
                        
                                                      "enable-funding": "paypal", // Only show PayPal as a funding option
                                                      "disable-funding": "card", // Disable debit/credit card option
                                                    }}>
                                                    <PayPalButtons
                                                     style={{
                                                    
                                                      color: "white",
                                                      shape: "pill",
                                                      height:55,
                                                      
                                            
                                                  }}
                                                  
                                                
                                                      createOrder={(data, actions) => {
                                                        return actions.order.create({
                                                          purchase_units: [
                                                            {
                                                              amount: {
                                                                value: amount, // Set the payment amount here
                                                              },
                                                            },
                                                          ],
                                                        });
                                                      }}
                                                      onApprove={(data, actions) => {
                                                        return actions.order
                                                          .capture()
                                                          .then(function (details) {
                                                            const transactionId = details.id;
                                                            const email = details.payer.email_address;
                        
                                                            // Store email in localStorage
                                                            
                                                            // Save transaction in database
                                                            dispatch(
                                                              paypalPayment({
                                                                amount: amount,
                                                                email: email,
                                                                id: id,
                                                                status: details.status,
                                                                centerId:centerData?._id ? centerData._id : centerData.id,
                                                                usercomment: usercomment,
                                                              })
                                                            )
                                                              .then(() => {
                                                                // After successful database save, navigate to success page
                                                                navigate(
                                                                  `/success?success=true&transactionId=${transactionId}`
                                                                );
                                                              })
                                                              .catch((error) => {
                                                                console.error(
                                                                  "Error saving transaction:",
                                                                  error
                                                                );
                                                                toast.error(
                                                                  "Payment was successful, but failed to save transaction."
                                                                );
                                                              });
                                                          });
                                                      }}
                                                      onError={(err) => {
                                                        if (!amount) {
                                                          toast.error("Please select an amount");
                                                        } else {
                                                          toast.error("An error occured");
                                                        }
                                                      }}
                                                    />
                                                  </PayPalScriptProvider>
                                                </div>
                                              </div>

                         <div className={style.donationbuttoncontainermain}>
                                            <div className={style.smallscreenabutton}>
                                              <button onClick={handleShare}>
                                                Share
                                                {/* Help spread the world */}
                                              </button>
                                            </div>
                                          </div>
                        <Pray centerId={id} />
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  <div className="mt-5 ">
                        <div className={`my-2 ${style.amountdonationn}`}>
                          ${centerDetail?.totalAmount} USD raised of $
                          {centerDetail?.goalAmount?.toLocaleString()}
                        </div>
                        <ProgressBar
                          variant="info"
                          animated
                          now={centerDetail?.percentage}
                        />
                        <div className={`my-2 ${style.headingform}`}>
                          Recent Donations
                        </div>
                        <div className={style.donationtable}>
                          <table className="table m-0">
                            <thead className="thead-dark border-top border-bottom">
                              <tr>
                                <th scope="col">DATE</th>
                               
                                <th scope="col">AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody className={style.noborder}>
                              {donationList?.map((item, index) => (
                                <tr key={index} className="border-none">
                                  <td>
                                    {handleDateConversion(item.createdAt)}
                                  </td>

                                  <td style={{ color: "#06A1D7" }}>
                                    ${item.amount}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      
                        {!donationList && (
                          <div className=" p-5 text-center">
                            <h5> No Data Found </h5>
                          </div>
                        )}
                      </div>
                                
              <Row className={style.desktopcomment}>
                <Col md={12} xl={12} lg={7}  className="p-0">
                  {comments?.length > 0 && (
                    <div className={style.mainsectioncomment}>
                      <h1>Comments</h1>
                      <div className={style.maincomment}>
                        <div className={style.innersection}>
                          {comments?.map((item, index) => (
                            <div key={index}>
                              {/* Dynamically render center name and amount */}
                              <h2>
                                <span>{item?.firstName}</span> - ${item?.amount}{" "}
                                USD
                              </h2>
                              {/* Render comment content dynamically */}
                              <p>{item.usercomment}</p>
                              <hr />
                            </div>
                          ))}
                        </div>
                    
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
                  <Row className={style.phonecomment}>
                <Col md={12} xl={12} lg={7}  className="p-0">
                  {comments?.length > 0 && (
                    <div className={style.mainsectioncomment}>
                      <h1>Comments</h1>
                      <div className={style.maincomment}>
                        <div className={style.innersection}>
                          {comments?.map((item, index) => (
                            <div key={index}>
                              {/* Dynamically render center name and amount */}
                              <h2>
                                <span>{item?.firstName}</span> - ${item?.amount}{" "}
                                USD
                              </h2>
                              {/* Render comment content dynamically */}
                              <p>{item.usercomment}</p>
                              <hr />
                            </div>
                          ))}
                        </div>
                        {/* <div className={style.innersection}>
                        <h2><span>{centerData?.centerName}</span> - ${centerDetail?.totalAmount} USD </h2>
                        <p>Lots of prayer for you</p>
                        <hr></hr>
                        </div>
                        <div className={style.innersection}>
                        <h2><span>{centerData?.centerName}</span> - ${centerDetail?.totalAmount} USD </h2>
                        <p>Lots of prayer for you</p>
                        <hr></hr>
                        </div> */}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
                     {/* <div>
                                    <Row>
                                   
                                      <Col xl={4} md={3} lg={4}>
                                        <img className="w-100" src="/blueprint.jpg" />
                                      </Col>
                  
                                      <Col xl={8} md={9} lg={8} className={style.contentdonate}>
                                        <p className={style.bookparadonate}>
                                          A crucial resource for crucial times. Abortion in the
                                          church?  Research reveals it is. Fifty-four percent of
                                          women who have had an abortion identify as Catholic or
                                          Protestant. Forty percent attended church regularly at
                                          the time of their ﬁrst abortion. Seventy-six percent
                                          felt that their church didn’t have a ministry they could
                                          turn to.
                                        </p>
                                      </Col>
                                    </Row>
                                  </div> */}
                                  {/* <div>
                                    <Row>
                                      <Col xl={4} md={3} lg={4}>
                                        <img className="w-100" src="/brainwashed.jpg" />
                                      </Col>
                  
                                      <Col xl={8} md={9} lg={8} className={style.contentdonate}>
                                        <p className={style.bookparadonate}>
                                          Though often linked to Hitler, Stalin (17M deaths), Mao
                                          Zedong (45M), Koresh, and Jones all used mind control to
                                          erase the stigma of mass murder. But, if there was an
                                          award given out for the propaganda campaign that
                                          influences the most and kills the most in the history of
                                          mankind, abortion rights activists would be the runaway
                                          winner.
                                        </p>
                                      </Col>
                                    </Row>
                                  </div> */}
                                  
                  {/* <div className={style.mainbooksumamry}>
                  <div className={style.booksummary}>
                    <h1>Donate Now And Get Free E-Books</h1>
                    <Row>
                      <Col md={4} xl={4} lg={4} xs={4} className={`text-center ${style.innerlist}`}>
                        <h2>Donation</h2>
                        <ul>
                          <li>Donate $25</li>
                          <li>Donate $50</li>
                          <li>Donate $75</li>
                          <li>Donate $100</li>
                        </ul>
                      </Col>

                      <Col md={4} xl={4} lg={4} xs={4}  className={`text-center ${style.innerlist}`}>
                      <h2>&nbsp;</h2> 
                        <ul>
                          <li>-</li>
                          <li>-</li>
                          <li>-</li>
                          <li>-</li>
                        </ul>
                      </Col>

                      <Col md={4} xl={4} lg={4} xs={4} className={`text-center ${style.innerlist}`}>
                        <h2>Book</h2>
                        <ul>
                          <li>1 Book</li>
                          <li>2 Books</li>
                          <li>3 Books</li>
                          <li>All Books</li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                  </div> */}
                </Col>
              </Row>
         
            
            </div>
          </div>
        </div>
        <VideoPlayer
          showVideoPlayer={showVideoPlayer}
          setShowVideoPlayer={setShowVideoPlayer}
          videoUrl={videoUrl}
          // isdata={isdata}
        />
        <TransactionStatus
          showTransactionStatus={showTransactionStatus}
          setShowTransactionStaus={setShowTransactionStaus}
        />
      </div>
    </>
  );
};

export default Donation;
